body {
  text-align: center;
  font-size: larger;
  color: saddlebrown;
  background-color: #eee;
}

@font-face {
  font-family: sansilk;
  src: url('./fonts/Sansilk-p71dD.ttf'),
    url('./fonts/Sansilk-x32eO.otf') format('opentype');
}

@font-face {
  font-family: Draco;
  src: url('./fonts/Draco.otf') format('opentype');
}


.top_Sign {
  font-family: sansilk;
  font-size: 2em;
  color: saddlebrown;

  @media screen and (min-width: 490px) {
    font-size: 5em;

  }
}

.adv_Sign {
  font-family: sansilk;
  font-size: 3em;
  color: saddlebrown;
  padding-top: 6px;
  padding-bottom: 15px;

  @media screen and (min-width: 490px) {
    font-size: 5em;

  }
}



a {
  font-size: medium;
  font-weight: 100;  
  text-decoration: none;  
  color: saddlebrown;
  padding-top: 5px;
  padding-bottom: 5px;
  

}

.container {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 5px;
}


.depart {
  font-size: medium;
  color: saddlebrown;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 5px;
  border: 3px solid saddlebrown;
  border-radius: 4px;
  line-height: 1;
  overflow: none;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.iconButton {
  width: 65px;
  height: 65px;
  border: 1px solid black;
  border-radius: 3px;
  overflow: none;

}

/*ANIMATIONS*/

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

/*SLIDEINDOWN END*/





/*END OF ANIMATIONS*/