@keyframes reveal {
  0% {
    transform: scale(0);
    transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
    opacity: 1;
  }
}
.form-sleep {
  display: none;
}

div.relative {
  position: relative;
  width: 400px;
  height: 200px;
}

.square-icon {
  border-radius: 32px;
  background: #e0e0e0;
  box-shadow: 5px 5px 3px #cccccc, -5px -5px 3px #f4f4f4;
}

.announcement {
  background-image: url("./mesh.png");
  padding: 25px;
}

.neu-button {
  border-radius: 15px;
  border-color: saddlebrown;
  padding: 10px 15px;
  box-shadow: 5px 5px 4px #bac0c4, -5px -5px 4px #ffffff;
  font-size: large;
  font-weight: bold;
  background-color: #eee;
}

.dep-wrapper {
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: center;
  margin: auto;
}
@media screen and (min-width: 568px) {
  .dep-wrapper {
    width: 30em;
  }
}

.cont {
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  padding: 20px;
  background-color: #eee;
  border-radius: 15px;
}

.images {
  max-width: 95%;
  height: auto;
  border-radius: 15px;
}

.dep {
  outline: none;
  border-style: none;
  border-radius: 15px;
  background-color: #eee;
  padding: 10px 15px;
  box-shadow: 5px 5px 10px #b9bbc4, -5px -5px 12px #fff;
}

.clicked_dep {
  border-radius: 15px;
  padding: 10px 15px;
  background: linear-gradient(45deg, #d7dde2, #ffffff);
  box-shadow: 8px -8px 16px #aaafb2, -8px 8px 16px #ffffff;
  font-size: medium;
}

.inset {
  outline: none;
  border-style: none;
  border-radius: 15px;
  background-color: #eee;
  padding: 10px 15px;
  box-shadow: inset -4px -4px 10px #fff, inset 4px 2px 12px #b9bbc4;
}

.outset {
  outline: none;
  border-style: none;
  border-radius: 15px;
  padding: 10px 15px;
  background: #e0e0e0;
  box-shadow: 7px 7px 14px #b3b3b3, -7px -7px 14px #ffffff;
}

.reveal {
  position: relative;
  transform: scaleX(0);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active {
  animation: reveal 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) forwards;
  opacity: 1;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-5-3 23:56:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation scale-out-hor-left
 * ----------------------------------------
 */
/* ----------------------------------------------
 * Generated by Animista on 2024-5-4 2:49:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
.scale-out-bl {
  animation: scale-out-bl 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/**
 * ----------------------------------------
 * animation scale-out-bl
 * ----------------------------------------
 */
@keyframes scale-out-bl {
  0% {
    transform: scale(1);
    transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    transform: scale(0);
    transform-origin: 0% 100%;
    opacity: 1;
  }
}
.puff-in-hor {
  animation: puff-in-hor 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}

@keyframes puff-in-hor {
  0% {
    transform: scaleX(2);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    filter: blur(0px);
    opacity: 1;
  }
}/*# sourceMappingURL=S.css.map */